import React from "react"

const sizePresets = {
    small: {
      width: 340,
      height: 150,
    },
    compact: {
      width: 300,
      height: 80,
    },
  }

function Substack({ size }) {
    return (
        <iframe
            title="Substack"
            className="Substack"
            src="https://choco.substack.com/embed"
            width={sizePresets[size].width}
            height={sizePresets[size].height}
            style={{border:`none`, background:`rgba(255, 0, 0, 0.0)`}}
            frameBorder="0"
            scrolling="no"
            />
    )
}

export default Substack