import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Substack from "./Substack";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi，我係Choco
    </Text>

    <p>{`我叫Choco，係一個有好多唔同興趣，又好愛香港，好愛呢個世界嘅人。`}<br parentName="p"></br>{`
`}{`由科技，學習到寫作，再由政治到創業，通通都鍾意睇，鍾意噏。`}<br parentName="p"></br>{`
`}{`呢個係我嘅網站，有我寫嘅文章，嚟緊亦會有更多搞作。`}</p>
    <p>{`逢禮拜五，我會寫一封叫做 `}<a target="_blank" rel="noopener noreferrer" href="https://choco.substack.com/subscribe"><strong parentName="p">{`Choco Espresso`}</strong></a>{` 嘅電子報。`}<br parentName="p"></br>{`
`}{`分享下我嘅諗法，又分享下呢個星期睇過啲乜有意思嘅嘢。  `}</p>
    <p>{`如果有興趣嘅可以訂閱：  `}</p>
    <Substack size="small" mdxType="Substack" />
    <p>{`祝你有開心嘅一日！`}<br parentName="p"></br>{`
`}{`Choco`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      